export async function loadAutocompleteAndClient() {
    await import('jquery');
    // const client = null;
    // const autocomplete = null;

    const {default: algoliasearch} = await import('algoliasearch');
    const {default: autocomplete} = await import('../vendor/algolia-autocomplete/jquery/plugin');
    var client = algoliasearch(
        'LFN8J4PA9S',
        'd016648758d5975d2f4d0972d91d557f'
    );
    return {
        client,
        autocomplete
    };
};

export async function loadSearchAutocompleteMenu(searchBoxInputId) {
    const {autocomplete, client} = await loadAutocompleteAndClient();
    var spaces = client.initIndex(
        window.hydrateJSON.algoliaSpaceIndex || $('#algolia_space_index').val()
    );
    var cities = client.initIndex(
        window.hydrateJSON.algoliaCityIndex || $('#algolia_city_index').val()
    );
    var countries = client.initIndex(
        window.hydrateJSON.algoliaCountryIndex || $('#algolia_country_index').val()
    );

    $('#' + searchBoxInputId).autocomplete(
        {
            debug: true,
            autoselect: true,
            templates: {
                dropdownMenu:
                    '<div class="aa-dataset-cities"></div>' +
                    '<div class="aa-dataset-spaces"></div>' +
                    '<div class="aa-dataset-countries"></div>',
            },
        },
       [
            {
                source: autocomplete.sources.hits(spaces, {hitsPerPage: 5, filters: ''}),
                displayKey: 'display_name',
                name: 'spaces',
                templates: {
                    suggestion: function (suggestion) {
                        if (suggestion.display_name == null) return null;
                         return (
                             '<span class="fa fa-buildingui"><span class="cw_font">' + suggestion._highlightResult.display_name.value + '</span></span>'
                         );
                    },
                },
            },
            {
                source: autocomplete.sources.hits(cities, {hitsPerPage: 4}),
                displayKey: 'display_name',
                name: 'cities',
                templates: {
                    suggestion: function (suggestion) {
                        return (
                            '<div>' +
                            '<span class="fa fa-map-markerui"><span class="cw_font">' + suggestion._highlightResult.display_name.value + '</span></span>' +
                            '</div>' +
                            '<div class="space_count">' +
                            '(<em>' + suggestion._highlightResult.total_spaces.value + '</em> spaces)' +
                            '</div>'
                        );
                    },
                },
            },
            {
                source: autocomplete.sources.hits(countries, {hitsPerPage: 2}),
                displayKey: 'display_name',
                name: 'countries',
                templates: {
                    suggestion: function (suggestion) {
                        return (
                            '<div>' +
                            '<span class="fa fa-globeui"><span class="cw_font">' + suggestion._highlightResult.display_name.value + '</span></span>' +
                            '</div>' +
                            '<div class="space_count">' +
                            '(<em>' + suggestion._highlightResult.total_spaces.value + '</em> spaces)' +
                            '</div>'
                        );
                    },
                },
            },
        ] 
    ).on('autocomplete:selected', function (event, suggestion, dataset) {
        if (dataset == 'spaces') {
            window.location.href = Page.baseUrl + suggestion.profile_page_url;
        } else if (dataset == 'cities') {
            window.location.href = Page.baseUrl + suggestion.search_page_url;
        } else if (dataset == 'countries') {
            window.location.href = Page.baseUrl + suggestion.search_page_url;
        } else {
            console.log('Not Found');
        }
    });

    $(document).on('click', function () {
        $('.aa-dropdown-menu').hide();
    });
}